import React, { PureComponent, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import 'moment/locale/nl';
import './AlbumOverView.css';
const AlbumPhotoList = lazy(() => import('./AlbumPhotoList'));

export default class AlbumOverView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      photoList: []
    };
  }
  componentDidMount() {
    if (this.props.post._photo_count !== 0) {
      this.getAllImages(this.props.post.photos);
    }
  }

  getAllImages(URL) {
    return fetch(URL)
      .then(response => {
        return response.json();
      })
      .then(json => {
        const newPhotoList = [...this.state.photoList, ...json.results];
        this.setState({ photoList: newPhotoList });
        const nextURL = json.next;
        if (!nextURL) {
          return;
        } else {
          this.getAllImages(nextURL);
        }
      });
  }

  render() {
    const post = this.props.post;
    let author;
    let authorPicture;
    if (post.posted_by) {
      if (post.posted_by.first_name) {
        author = `${post.posted_by.first_name} ${post.posted_by.last_name}`;
      } else {
        author = `${post.posted_by.last_name}`;
      }
      authorPicture = post.posted_by.picture;
    }
    const community = post._community_name;
    return (
      <div className="card mb-3 postContainer">
        <div className="card postOverviewheader bg-primary mb-3">
          <div className="card-header">
            <h4 className="card-title postTitle mb-1 mt-1">{post.title}</h4>
          </div>
        </div>
        <div className="card-body postDetailContainer">
          <div className="row d-flex flex-row authorDetailsTop mb-3 pr-3 pl-3">
            <div className="authorPicture pr-3">
              <img src={authorPicture} className="postedbyImg" alt="" />
            </div>
            <div className="authorDetailContainer">
              <span className="authorDetails">
                {author} in {community}
              </span>
              <span>
                op{' '}
                <Moment format="D MMMM YYYY" locale="nl">
                  {post.date_created}
                </Moment>
              </span>
            </div>
          </div>
          <div className="postDetailContainer postPhotos">
            <h4 className="postTitle">{post.title}</h4>
            <Suspense fallback={<div>Loading photos....</div>}>
              <AlbumPhotoList photos={this.state.photoList} />
            </Suspense>
          </div>
        </div>
      </div>
    );
  }
}

AlbumOverView.propTypes = {
  post: PropTypes.object
};
