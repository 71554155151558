import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import PhotoGridList from './PhotoGridList';
import './PhotoGridApp.css';

export default class PhotoGridApp extends PureComponent {
  constructor(props) {
    super(props);
    let baseURL = 'https://api.socialschools.eu';
    this.communityId = this.props.communityId;
    const show = parseInt(this.props.show, 10);
    const moreURL = this.props.url;
    let finalURL = `${baseURL}/apiv1/public/${this.communityId}/photo?number_of_items=12`;
    if (show === 1) {
      finalURL = `${baseURL}/apiv1/public/${this.communityId}/photo?number_of_items=12&community_and_descendants=true`;
    } else if (show === 3) {
      finalURL = `${baseURL}/apiv1/public/${this.communityId}/photo?number_of_items=12&only_descendants=true`;
    }
    this.requestURL = finalURL;
    this.state = {
      photoList: null,
      next: null,
      previous: null,
      moreURL: moreURL
    };
  }

  componentDidMount() {
    const self = this;
    fetch(this.requestURL)
      .then(response => {
        return response.json();
      })
      .then(json => {
        self.setState({
          photoList: json.results,
          next: json.next,
          previous: json.previous
        });
      });
  }

  loadPrevious(event) {
    event.preventDefault();
    const requestURL = this.state.previous;
    if (requestURL !== null) {
      fetch(requestURL)
        .then(response => {
          return response.json();
        })
        .then(json => {
          // const newPostList = [...this.state.postsList, ...json.results];
          this.setState({
            photoList: json.results,
            next: json.next,
            previous: json.previous
          });
        });
    }
  }

  loadNext(event) {
    event.preventDefault();
    const requestURL = this.state.next;
    if (requestURL !== null) {
      fetch(requestURL)
        .then(response => {
          return response.json();
        })
        .then(json => {
          // const newPostList = [...this.state.postsList, ...json.results];
          this.setState({
            photoList: json.results,
            next: json.next,
            previous: json.previous
          });
        });
    }
  }

  render() {
    const nexttoggl = this.state.next ? '' : 'disabled';
    const nextbntClass = `btn btn-default loadMore float-right photos`;
    const previoustoggl = this.state.previous ? '' : 'disabled';
    const prevbtnClass = `btn btn-default loadMore photos float-left`;
    const isEmpty =
      this.state.photoList === null || this.state.photoList.length === 0;
    return (
      <div className="PhotoGridContainer mt-3">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div id="container" className="gallery-container">
            {!isEmpty && (
              <Fragment>
                <PhotoGridList photos={this.state.photoList} />
                <div className="clearfix margin-bottom"></div>
                <div className="center clearfix">
                  <a
                    className={prevbtnClass}
                    onClick={this.loadPrevious.bind(this)}
                    disabled={previoustoggl}
                    aria-disabled={this.state.previous}
                    aria-roledescription="loadPrevious"
                  >
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </a>
                  {this.state.moreURL && (
                    <a
                      href={this.state.moreURL}
                      className="btn btn-default btn-apps btn-apps-photo"
                    >
                      Bekijk fotoalbums
                    </a>
                  )}
                  <a
                    className={nextbntClass}
                    onClick={this.loadNext.bind(this)}
                    disabled={nexttoggl}
                    aria-disabled={this.state.next}
                    aria-roledescription="loadNext"
                  >
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </a>
                </div>
              </Fragment>
            )}
            {isEmpty && <p>Er zijn geen nieuwe foto's.</p>}
          </div>
        </div>
      </div>
    );
  }
}

PhotoGridApp.propTypes = {
  communityId: PropTypes.string,
  show: PropTypes.string
};
