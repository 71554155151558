import 'es6-shim';
import React from 'react';
import values from 'object.values';
import PhotoGridApp from './PhotoGrid/PhotoGridApp';
import PhotoGalleryApp from './PhotoGallery/PhotoGalleryApp';
import PhotoAlbumApp from './PhotoAlbum/PhotoAlbumApp';
import {createRoot} from "react-dom/client";


function renderPhotoGrid() {
  const photoGridRoot = [].slice.call(
    document.getElementsByClassName('photogridroot')
  );
  values(photoGridRoot).forEach(elem => {
    createRoot(elem).render(
      <PhotoGridApp
        communityId={elem.dataset.communityId}
        show={elem.dataset.show}
        url={elem.dataset.url}
      />
    );
  });
}

function renderPhotoGallery() {
  const photoGalleryRoot = [].slice.call(
  document.getElementsByClassName('photogalleryroot')
  );
  values(photoGalleryRoot).forEach(elem => {
     createRoot(elem).render(
      <PhotoGalleryApp
        communityId={elem.dataset.communityId}
        show={elem.dataset.show}
        url={elem.dataset.url}
      />
    );
  });
}

function renderPhotoAlbum() {
  const photoAlbumRoot = [].slice.call(
  document.getElementsByClassName('photalbumroot')
  );
  values(photoAlbumRoot).forEach(elem => {
     createRoot(elem).render(
      <PhotoAlbumApp
        communityId={elem.dataset.communityId}
        show={elem.dataset.show}
      />
    );
  });
}

renderPhotoGallery();
renderPhotoGrid();
renderPhotoAlbum();


document.addEventListener('DOMContentLoaded', function() {
  // Handler when the DOM is fully loaded
  if (window.CMS !== undefined) {
    window.CMS.$(window).on('cms-content-refresh', function() {
      renderPhotoGallery();
      renderPhotoGrid();
      renderPhotoAlbum();
    });
  }
});
