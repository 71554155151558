import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';

export default class PhotoGallery extends PureComponent {
  render() {
    const photos = this.props.photos;
    const IMAGES = [];

    if (photos !== null) {
      photos.map(photo => {
        IMAGES.push({ original: photo.picture, thumbnail: photo.thumbnails });
      });
    }

    console.log(IMAGES);
    return <ImageGallery items={IMAGES} />;
  }
}

PhotoGallery.propTypes = {
  photos: PropTypes.array
};
