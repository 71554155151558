import React, { PureComponent, Fragment, lazy, Suspense } from 'react';
import './image-gallery.css';
import './PhotoGallery.css';
const PhotoGallery = lazy(() => import('./PhotoGallery'));

export default class PhotoGalleryApp extends PureComponent {
  constructor(props) {
    super(props);
    this.communityId = this.props.communityId;
    const show = parseInt(this.props.show, 10);
    const moreURL = this.props.url;
    let baseURL = 'https://api.socialschools.eu';
    let finalURL = `${baseURL}/apiv1/public/${this.communityId}/photo?number_of_items=12`;
    if (show === 1) {
      finalURL = `${baseURL}/apiv1/public/${this.communityId}/photo?number_of_items=12&community_and_descendants=true`;
    } else if (show === 3) {
      finalURL = `${baseURL}/apiv1/public/${this.communityId}/photo?number_of_items=12&only_descendants=true`;
    }
    this.requestURL = finalURL;
    this.state = {
      photoList: null,
      moreURL: moreURL
    };
  }

  componentDidMount() {
    const self = this;
    fetch(this.requestURL)
      .then(response => {
        return response.json();
      })
      .then(json => {
        self.setState({ photoList: json.results });
      });
  }

  render() {
    const isEmpty =
      this.state.photoList === null || this.state.photoList.length === 0;
    return (
      <div className="PhotoGalleryContainer mt-3">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div id="container" className="gallery-container">
            <section data-reactroot className="app">
              {!isEmpty && (
                <Fragment>
                  <Suspense fallback={<div>Loading photos..</div>}>
                    <PhotoGallery photos={this.state.photoList} />
                  </Suspense>
                  <div className="center clearfix">
                    {this.state.moreURL && (
                      <a
                        href={this.state.moreURL}
                        className="btn btn-default btn-apps btn-apps-photo"
                      >
                        Bekijk fotoalbums
                      </a>
                    )}
                  </div>
                </Fragment>
              )}
              {isEmpty && <p>Er zijn geen nieuwe foto's.</p>}
            </section>
          </div>
        </div>
      </div>
    );
  }
}
