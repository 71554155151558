import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AlbumOverView from './AlbumOverView';

export default class AlbumOverviewList extends PureComponent {
  render() {
    let postContainer = null;
    const postData = this.props.postData;

    if (postData !== null) {
      postContainer = [];
      postData.forEach((value, key) => {
        postContainer.push(<AlbumOverView key={key.toString()} post={value} />);
      });
    }

    return (
      <div className="postListContainer">
        {postContainer}
      </div>
    );
  }
}

AlbumOverviewList.propTypes = {
  postData: PropTypes.array,
};
