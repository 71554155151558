import React, { PureComponent } from 'react';
import AlbumOverviewList from './AlbumOverviewList';

import './PhotoAlbumApp.css';

export default class PhotoAlbumApp extends PureComponent {
  constructor(props) {
    super(props);
    const communityID = this.props.communityId;
    const show = parseInt(this.props.show, 10);
    let baseURL = 'https://api.socialschools.eu';
    let finalURL = `${baseURL}/apiv1/public/${communityID}/post?only_photos=true`;
    if (show === 1) {
      finalURL = `${baseURL}/apiv1/public/${communityID}/post?only_photos=true&community_and_descendants=true`;
    } else if (show === 3) {
      finalURL = `${baseURL}/apiv1/public/${communityID}/post?only_photos=true&only_descendants=true`;
    }
    this.requestURL = finalURL;
    this.state = {
      postList: null
    };
  }

  componentDidMount() {
    const self = this;
    fetch(this.requestURL)
      .then(response => {
        return response.json();
      })
      .then(json => {
        self.setState({ postList: json.results });
      });
  }

  render() {
    const isEmpty =
      this.state.postList === null || this.state.postList.length === 0;
    return (
      <div className="AlbumOverViewContainer">
        {!isEmpty && <AlbumOverviewList postData={this.state.postList} />}
        {isEmpty && <p>Er zijn geen nieuwe foto's.</p>}
      </div>
    );
  }
}
