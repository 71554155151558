import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Gallery from 'react-grid-gallery';

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export default class PhotoGridList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      index: -1
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick = ({ index }) => {
    this.setState({ index });
  };

  handleClose = () => {
    this.setState({ index: -1 });
  };
  render() {
    const { index } = this.state;
    const photos = this.props.photos;
    const IMAGES = [];
    if (photos !== null) {
      photos.forEach((value, key) => {
        const image = {
          src: value.picture,
          thumbnail: value.thumbnails,
          width: 1200,
          height: 800
        };
        IMAGES.push(image);
      });
    }
    return (
    <div className="photo-grid-list">
      <PhotoAlbum
        photos={IMAGES}
        layout="rows"
        targetRowHeight={140}
        onClick={this.handleClick}
      />

      <Lightbox
        slides={IMAGES}
        open={index >= 0}
        index={index}
        close={this.handleClose}
        plugins={[Fullscreen, Slideshow, Zoom]}
      />
    </div>
    );
  }
}

PhotoGridList.propTypes = {
  photos: PropTypes.array
};
